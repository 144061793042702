<template>
  <div id="loading-bg">
    <div class="loading-logo" style="display: inline-flex">
      <img
        src="log-psim-without-text.png"
        alt="Logo"
        style="display: block; margin: 0 auto; height: 60px"
      />
      <h1
        style="
          color: white;
          margin-left: 1rem;
          margin-top: auto;
          margin-bottom: auto;
          font-size: xxx-large;
        "
      >
        SBO
      </h1>
    </div>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>

<script>
import usePassport from "@/auth/passport/usePassport";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";
import axios from "@axios";
import store from "@/store";

export default {
  methods: {
    login() {
      const access_token = router.currentRoute.query.access_token;

      if (access_token == "" || access_token == undefined) {
        router.push({ name: "login" });
      }

      setTimeout(async () => {
        try {
          const response = await axios.get("/v1/users/data", {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          });

          if (response.data.success) {
            const { data } = response.data;
            const userData = data;
            userData.ability = [];

            if (userData.video_wall) {
              store
                .dispatch("video_wall/online", userData)
                .then((response) => {
                  console.log(response);
                })
                .catch((response) => console.log(response));
            }
            if (userData.is_master_admin) {
              userData.ability.push({
                action: "manage",
                subject: "all",
              });
            } else {
              userData.ability = data.abilities;
              userData.ability.push({
                action: "read",
                subject: "Auth",
              });

              userData.ability.push({
                action: "read",
                subject: "Home",
              });
            }

            usePassport.setToken(access_token);
            this.$ability.update(userData.ability);
            localStorage.setItem("userData", JSON.stringify(userData));
            store.dispatch("user/setUserDataFromLocalStorage", userData);

            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hola ${userData.first_name}`,
                icon: "CoffeeIcon",
                variant: "success",
                text: "Bienvenid@ de nuevo",
              },
            });

            router.replace(getHomeRouteForLoggedInUser(userData));
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: error.response?.data?.message ?? "Error al inciar sesión",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });

          router.push({ name: "login" });
        }
      }, 2000);
    },
  },
  mounted() {
    this.login();
  },
};
</script>
